import { LayoutAuth, LayoutDefault, RouteWrapper } from '@/components/layouts'

export const publicRoute = [
  {
    path: '*',
    component: () => import('@/views/error/NotFound.vue'),
  },
  {
    path: '/auth',
    component: LayoutAuth,
    meta: { title: 'auth_login' },
    redirect: '/auth/login',
    hidden: true,
    children: [
      {
        path: 'login',
        name: 'Login',
        meta: { title: 'auth_login' },
        component: () => import(/* webpackChunkName: "login" */ '@/views/auth/Login.vue'),
      },
      {
        path: 'logout',
        name: 'Logout',
        meta: { title: 'auth_logout' },
        component: () => import(/* webpackChunkName: "login" */ '@/views/auth/Logout.vue'),
      },
    ],
  },

  {
    path: '/password',
    component: LayoutAuth,
    meta: { title: 'password' },
    redirect: '/password/reminder',
    hidden: true,
    children: [
      {
        path: 'reminder',
        name: 'PasswordReminder',
        meta: { title: 'password_reminder' },
        component: () => import(/* webpackChunkName: "login" */ '@/views/password/Reminder.vue'),
      },
    ],
  },

  {
    path: '/403',
    name: 'Forbidden',
    meta: {
      title: 'error_403',
      hiddenInMenu: true,
    },
    component: () => import('@/views/error/Deny.vue'),
  },

  {
    path: '/404',
    name: '404',
    meta: {
      title: 'error_404',
    },
    component: () => import('@/views/error/NotFound.vue'),
  },

  {
    path: '/500',
    name: '500',
    meta: {
      title: 'error_500',
    },
    component: () => import('@/views/error/Error.vue'),
  },
]

export const protectedRoute = [
  {
    path: '/',
    component: LayoutDefault,
    meta: { title: 'top', icon: '' },
    children: [
      {
        path: '',
        name: 'TopIndex',
        meta: {
          title: 'top_index',
          metaTags: {
            name: 'robots',
            content: 'noindex',
          },
        },
        component: () => import('@/views/top/Index.vue'),
      },
      {
        path: '/member',
        component: RouteWrapper,
        meta: {
          title: 'member',
          icon: 'face',
        },
        redirect: '/member/index',
        children: [
          {
            path: '/member/index',
            name: 'MemberIndex',
            meta: {
              title: 'member_index',
              icon: 'list_alt',
            },
            component: () => import('@/views/member/Index.vue'),
          },
          {
            path: '/member/create',
            name: 'MemberCreate',
            meta: {
              title: 'member_create',
              icon: 'add',
            },
            component: () => import('@/views/member/Create.vue'),
          },
          {
            path: '/member/detail/:member_id',
            name: 'MemberDetail',
            meta: {
              title: 'member_detail',
              icon: 'pageview',
              hidden: true,
            },
            component: () => import('@/views/member/Detail.vue'),
          },
        ],
      },
      {
        path: '/reservation',
        component: RouteWrapper,
        meta: {
          title: 'reservation',
          icon: 'book_online',
        },
        redirect: '/reservation/index',
        children: [
          {
            path: '/reservation/index',
            name: 'ReservationIndex',
            meta: {
              title: 'reservation_index',
              icon: 'list_alt',
            },
            component: () => import('@/views/reservation/Index.vue'),
          },
          {
            path: '/reservation/create',
            name: 'ReservationCreate',
            meta: {
              title: 'reservation_create',
              icon: 'add',
            },
            component: () => import('@/views/reservation/Create.vue'),
          },
          {
            path: '/reservation/create/:reservation_id',
            name: 'ReservationCreateCopy',
            meta: {
              title: 'reservation_create',
              icon: 'add',
            },
            component: () => import('@/views/reservation/Create.vue'),
          },
          {
            path: '/reservation/detail/:reservation_id',
            name: 'ReservationDetail',
            meta: {
              title: 'reservation_detail',
              icon: 'pageview',
              hidden: true,
            },
            component: () => import('@/views/reservation/Detail.vue'),
          },
          {
            path: '/reservation/timetable',
            name: 'ReservationTimetable',
            meta: {
              title: 'reservation_timetable',
              icon: 'view_timeline',
            },
            component: () => import('@/views/reservation/Timetable.vue'),
          },
        ],
      },
      {
        path: '/onlineform',
        component: RouteWrapper,
        meta: {
          title: 'onlineform',
          icon: 'article',
        },
        redirect: '/onlineform/index',
        children: [
          {
            path: '/onlineform_application/index',
            name: 'OnlineformApplicationIndex',
            meta: {
              title: 'onlineform_application_index',
              icon: 'list_alt',
            },
            component: () => import('@/views/onlineform_application/Index.vue'),
          },
          {
            path: '/onlineform_application/detail/:onlineform_application_id',
            name: 'OnlineformApplicationDetail',
            meta: {
              title: 'onlineform_application_detail',
              icon: 'pageview',
              hidden: true,
            },
            component: () => import('@/views/onlineform_application/Detail.vue'),
          },
          {
            path: '/onlineform/index',
            name: 'OnlineformIndex',
            meta: {
              title: 'onlineform_index',
              icon: 'list_alt',
            },
            component: () => import('@/views/onlineform/Index.vue'),
          },
          {
            path: '/onlineform/create',
            name: 'OnlineformCreate',
            meta: {
              title: 'onlineform_create',
              icon: 'add',
            },
            component: () => import('@/views/onlineform/Create.vue'),
          },
          {
            path: '/onlineform/create/:onlineform_id',
            name: 'OnlineformCreateCopy',
            meta: {
              title: 'onlineform_create',
              icon: 'add',
            },
            component: () => import('@/views/onlineform/Create.vue'),
          },
          {
            path: '/onlineform/detail/:onlineform_id',
            name: 'OnlineformDetail',
            meta: {
              title: 'onlineform_detail',
              icon: 'pageview',
              hidden: true,
            },
            component: () => import('@/views/onlineform/Detail.vue'),
          },
        ],
      },
      {
        path: '/member_activity',
        component: RouteWrapper,
        meta: {
          title: 'member_activity',
          icon: 'fingerprint',
        },
        redirect: '/member_activity/bus',
        children: [
          {
            path: '/member_activity/bus',
            name: 'MemberActivityBus',
            meta: {
              title: 'member_activity_bus',
              icon: 'list_alt',
            },
            component: () => import('@/views/member_activity/Bus.vue'),
          },
          {
            path: '/member_activity/office',
            name: 'MemberActivityOffice',
            meta: {
              title: 'member_activity_office',
              icon: 'list_alt',
            },
            component: () => import('@/views/member_activity/Office.vue'),
          },
        ],
      },
      {
        path: '/spot',
        component: RouteWrapper,
        meta: {
          title: 'spot',
          icon: 'location_on',
        },
        redirect: '/spot/index',
        children: [
          {
            path: '/spot/index',
            name: 'SpotIndex',
            meta: {
              title: 'spot_index',
              icon: 'list_alt',
            },
            component: () => import('@/views/spot/Index.vue'),
          },
          {
            path: '/spot/create',
            name: 'SpotCreate',
            meta: {
              title: 'spot_create',
              icon: 'add',
            },
            component: () => import('@/views/spot/Create.vue'),
          },
          {
            path: '/spot/create/:spot_id',
            name: 'SpotCreateCopy',
            meta: {
              title: 'spot_create',
              icon: 'add',
            },
            component: () => import('@/views/spot/Create.vue'),
          },
          {
            path: '/spot/detail/:spot_id',
            name: 'SpotDetail',
            meta: {
              title: 'spot_detail',
              icon: 'pageview',
              hidden: true,
            },
            component: () => import('@/views/spot/Detail.vue'),
          },
        ],
      },
      {
        path: '/room',
        component: RouteWrapper,
        meta: {
          title: 'room',
          icon: 'meeting_room',
        },
        redirect: '/room/index',
        children: [
          {
            path: '/room/index',
            name: 'RoomIndex',
            meta: {
              title: 'room_index',
              icon: 'list_alt',
            },
            component: () => import('@/views/room/Index.vue'),
          },
          {
            path: '/room/create',
            name: 'RoomCreate',
            meta: {
              title: 'room_create',
              icon: 'add',
            },
            component: () => import('@/views/room/Create.vue'),
          },
          {
            path: '/room/create/:room_id',
            name: 'RoomCreateCopy',
            meta: {
              title: 'room_create',
              icon: 'add',
            },
            component: () => import('@/views/room/Create.vue'),
          },
          {
            path: '/room/detail/:room_id',
            name: 'RoomDetail',
            meta: {
              title: 'room_detail',
              icon: 'pageview',
              hidden: true,
            },
            component: () => import('@/views/room/Detail.vue'),
          },
        ],
      },
      {
        path: '/equipment',
        component: RouteWrapper,
        meta: {
          title: 'equipment',
          icon: 'print',
        },
        redirect: '/equipment/index',
        children: [
          {
            path: '/equipment/index',
            name: 'EquipmentIndex',
            meta: {
              title: 'equipment_index',
              icon: 'list_alt',
            },
            component: () => import('@/views/equipment/Index.vue'),
          },
          {
            path: '/equipment/create',
            name: 'EquipmentCreate',
            meta: {
              title: 'equipment_create',
              icon: 'add',
            },
            component: () => import('@/views/equipment/Create.vue'),
          },
          {
            path: '/equipment/create/:equipment_id',
            name: 'EquipmentCreateCopy',
            meta: {
              title: 'equipment_create',
              icon: 'add',
            },
            component: () => import('@/views/equipment/Create.vue'),
          },
          {
            path: '/equipment/detail/:equipment_id',
            name: 'EquipmentDetail',
            meta: {
              title: 'equipment_detail',
              icon: 'pageview',
              hidden: true,
            },
            component: () => import('@/views/equipment/Detail.vue'),
          },
        ],
      },
      {
        path: '/route',
        component: RouteWrapper,
        meta: {
          title: 'route',
          icon: 'route',
        },
        redirect: '/route/index',
        children: [
          {
            path: '/route/index',
            name: 'RouteIndex',
            meta: {
              title: 'route_index',
              icon: 'list_alt',
            },
            component: () => import('@/views/route/Index.vue'),
          },
          {
            path: '/route/create',
            name: 'RouteCreate',
            meta: {
              title: 'route_create',
              icon: 'add',
            },
            component: () => import('@/views/route/Create.vue'),
          },
          {
            path: '/route/detail/:route_id',
            name: 'RouteDetail',
            meta: {
              title: 'route_detail',
              icon: 'pageview',
              hidden: true,
            },
            component: () => import('@/views/route/Detail.vue'),
          },
          {
            path: '/route/timetable',
            name: 'RouteTimetable',
            meta: {
              title: 'route_timetable',
              icon: 'view_timeline',
            },
            component: () => import('@/views/route/Timetable.vue'),
          },
        ],
      },
      {
        path: '/vehicle',
        component: RouteWrapper,
        meta: {
          title: 'vehicle',
          icon: 'local_shipping',
        },
        redirect: '/vehicle/index',
        children: [
          {
            path: '/vehicle/index',
            name: 'VehicleIndex',
            meta: {
              title: 'vehicle_index',
              icon: 'list_alt',
            },
            component: () => import('@/views/vehicle/Index.vue'),
          },
          {
            path: '/vehicle/create',
            name: 'VehicleCreate',
            meta: {
              title: 'vehicle_create',
              icon: 'add',
            },
            component: () => import('@/views/vehicle/Create.vue'),
          },
          {
            path: '/vehicle/detail/:vehicle_id',
            name: 'VehicleDetail',
            meta: {
              title: 'vehicle_detail',
              icon: 'pageview',
              hidden: true,
            },
            component: () => import('@/views/vehicle/Detail.vue'),
          },
        ],
      },
      {
        path: '/notice',
        component: RouteWrapper,
        meta: {
          title: 'notice',
          icon: 'campaign',
        },
        redirect: '/notice/index',
        children: [
          {
            path: '/notice/index',
            name: 'NoticeIndex',
            meta: {
              title: 'notice_index',
              icon: 'list_alt',
            },
            component: () => import('@/views/notice/Index.vue'),
          },
          {
            path: '/notice/create',
            name: 'NoticeCreate',
            meta: {
              title: 'notice_create',
              icon: 'add',
            },
            component: () => import('@/views/notice/Create.vue'),
          },
          {
            path: '/notice/detail/:notice_id',
            name: 'NoticeDetail',
            meta: {
              title: 'notice_detail',
              icon: 'pageview',
              hidden: true,
            },
            component: () => import('@/views/notice/Detail.vue'),
          },
        ],
      },
      {
        path: '/faq',
        component: RouteWrapper,
        meta: {
          title: 'faq',
          icon: 'quiz',
        },
        redirect: '/faq/index',
        children: [
          {
            path: '/faq/index',
            name: 'FaqIndex',
            meta: {
              title: 'faq_index',
              icon: 'list_alt',
            },
            component: () => import('@/views/faq/Index.vue'),
          },
          {
            path: '/faq/create',
            name: 'FaqCreate',
            meta: {
              title: 'faq_create',
              icon: 'add',
            },
            component: () => import('@/views/faq/Create.vue'),
          },
          {
            path: '/faq/detail/:faq_id',
            name: 'FaqDetail',
            meta: {
              title: 'faq_detail',
              icon: 'pageview',
              hidden: true,
            },
            component: () => import('@/views/faq/Detail.vue'),
          },
        ],
      },
      {
        path: '/organization',
        component: RouteWrapper,
        meta: {
          title: 'organization',
          icon: 'domain',
        },
        redirect: '/organization/index',
        children: [
          {
            path: '/organization/index',
            name: 'OrganizationIndex',
            meta: {
              title: 'organization_index',
              icon: 'list_alt',
            },
            component: () => import('@/views/organization/Index.vue'),
          },
          {
            path: '/organization/create',
            name: 'OrganizationCreate',
            meta: {
              title: 'organization_create',
              icon: 'add',
            },
            component: () => import('@/views/organization/Create.vue'),
          },
          {
            path: '/organization/detail/:organization_id',
            name: 'OrganizationDetail',
            meta: {
              title: 'organization_detail',
              icon: 'pageview',
              hidden: true,
            },
            component: () => import('@/views/organization/Detail.vue'),
          },
        ],
      },
      {
        path: '/group',
        component: RouteWrapper,
        meta: {
          title: 'group',
          icon: 'group',
        },
        redirect: '/group/index',
        children: [
          {
            path: '/group/index',
            name: 'GroupIndex',
            meta: {
              title: 'group_index',
              icon: 'list_alt',
            },
            component: () => import('@/views/group/Index.vue'),
          },
          {
            path: '/group/create',
            name: 'GroupCreate',
            meta: {
              title: 'group_create',
              icon: 'add',
            },
            component: () => import('@/views/group/Create.vue'),
          },
          {
            path: '/group/detail/:group_id',
            name: 'GroupDetail',
            meta: {
              title: 'group_detail',
              icon: 'pageview',
              hidden: true,
            },
            component: () => import('@/views/group/Detail.vue'),
          },
        ],
      },
      {
        path: '/user',
        component: RouteWrapper,
        meta: {
          title: 'user',
          icon: 'person',
        },
        redirect: '/user/index',
        children: [
          {
            path: '/user/index',
            name: 'UserIndex',
            meta: {
              title: 'user_index',
              icon: 'list_alt',
            },
            component: () => import('@/views/user/Index.vue'),
          },
          {
            path: '/user/create',
            name: 'UserCreate',
            meta: {
              title: 'user_create',
              icon: 'add',
            },
            component: () => import('@/views/user/Create.vue'),
          },
          {
            path: '/user/detail/:user_id',
            name: 'UserDetail',
            meta: {
              title: 'user_detail',
              icon: 'pageview',
              hidden: true,
            },
            component: () => import('@/views/user/Detail.vue'),
          },
          {
            path: '/user/otp/:user_id',
            name: 'UserOtp',
            meta: {
              title: 'user_otp',
              icon: 'pageview',
              hidden: true,
            },
            component: () => import('@/views/user/Otp.vue'),
          },
        ],
      },
      {
        path: '/operation_history',
        component: RouteWrapper,
        meta: {
          title: 'operation_history',
          icon: 'history',
        },
        redirect: '/operation_history/index',
        children: [
          {
            path: '/operation_history/index',
            name: 'OperationHistoryIndex',
            meta: {
              title: 'operation_history_index',
              icon: 'list_alt',
            },
            component: () => import('@/views/operation_history/Index.vue'),
          },
          {
            path: '/member_operation_history/index',
            name: 'MemberOperationHistoryIndex',
            meta: {
              title: 'member_operation_history_index',
              icon: 'list_alt',
            },
            component: () => import('@/views/member_operation_history/Index.vue'),
          },
        ],
      },
    ],
  },
]
