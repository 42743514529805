<template>
  <div class="themeSetting">
    <v-toolbar color="primary">
      <v-toolbar-title class="white--text">
        {{ $t('menu.theme_setting') }}
      </v-toolbar-title>
    </v-toolbar>
    <v-container>
      <v-row column>
        <v-col>
          <v-subheader class="px-1 my-2">
            {{ $t('page.theme_setting.theme_color_setting') }}
          </v-subheader>
          <v-divider />
          <div class="color-option">
            <v-item-group v-model="themeColor">
              <template v-for="item in themeColorOptions">
                <v-item :key="item" v-slot="{ active }" :value="item">
                  <v-avatar
                    :class="active ? 'active' : ''"
                    class="color-option__item"
                    :color="colors[item].base"
                    size="56"
                    tile
                    @click="handleChangeColor(item)"
                  >
                    <v-scroll-y-transition>
                      <v-icon v-if="active" color="white"> check </v-icon>
                    </v-scroll-y-transition>
                  </v-avatar>
                </v-item>
              </template>
            </v-item-group>
          </div>
          <div class="theme-options">
            <v-subheader class="px-1 my-2">
              {{ $t('page.theme_setting.background_color_setting') }}
            </v-subheader>
            <v-divider />
            <div class="my-3">
              <v-btn-toggle v-model="themeMode">
                <v-btn text value="dark">
                  {{ $t('page.theme_setting.dark') }}
                </v-btn>
                <v-btn text value="light">
                  {{ $t('page.theme_setting.light') }}
                </v-btn>
              </v-btn-toggle>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import colors from 'vuetify/es5/util/colors'

export default {
  data() {
    return {
      colors: colors,
    }
  },

  computed: {
    themeColorOptions() {
      return [
        'red',
        'pink',
        'purple',
        'deepPurple',
        'indigo',
        'blue',
        'lightBlue',
        'cyan',
        'teal',
        'green',
        'lightGreen',
        'lime',
        'yellow',
        'amber',
        'orange',
        'deepOrange',
        'brown',
        'blueGrey',
        'grey',
      ]
    },
    themeColor: {
      get() {
        return this.$store.getters.getThemeColor
      },
      set(val) {
        this.$vuetify.theme.themes.light.primary = colors[val].base
        this.$vuetify.theme.themes.dark.primary = colors[val].base
        this.$vuetify.theme.themes.light.accent = colors[val].lighten3
        this.$vuetify.theme.themes.dark.accent = colors[val].darken3
        this.$store.commit('setThemeColor', val)
      },
    },
    themeMode: {
      get() {
        return this.$store.getters.getThemeMode
      },
      set(val) {
        this.$vuetify.theme.dark = val === 'dark'
        this.$store.commit('setThemeMode', val)
      },
    },
  },

  created() {
    this.themeColor = this.$store.getters.getThemeColor
    this.themeMode = this.$store.getters.getThemeMode
  },

  methods: {
    handleChangeColor(option) {
      this.themeColor = option
    },
  },
}
</script>
<style lang="sass" scoped>
.color-option
  &__item
    display: inline-flex
    margin: 3px
    cursor: pointer
</style>
