import 'material-design-icons-iconfont/dist/material-design-icons.css'
import '@fortawesome/fontawesome-free/css/all.min.css'
import 'mavon-editor/dist/css/index.css'
import './theme/default.sass'
import './theme/additional.scss'

import App from './App.vue'
import Vue from 'vue'
import router from './router/'
import store from './store/'
import axios from './plugins/axios'
import i18n from './plugins/i18n'
import vuetify from './plugins/vuetify'

Vue.config.productionTip = false
Vue.prototype.$axios = axios

const app = new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
})
app.$mount('#app')
